import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Loginrequest } from '../model/Loginrequest';
import { Auth } from '../model/Auth';
import { Users } from '../model/Users';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ForgetRequest } from '../model/ForgetRequest';
import { ExternApi } from '../../administration-management/service/extern-apis/extern-apis.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authUrl = environment.auth;
  private apiUrl = 'https://gateway.manajero.com/administration/ExternalApis';

  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    }),
  };

  constructor(private http: HttpClient) {}

  logout(email: string): Observable<any> {
    return this.http.put<any>(`${this.authUrl}logout/${email}`, {}, this.httpOptions);
  }

  register(auth: Auth): Observable<Auth> {
    return this.http.post<Auth>(`${this.authUrl}signup`, auth, this.httpOptions);
  }

  login(loginRequest: Loginrequest): Observable<Auth> {
    return this.http.post<Auth>(`${this.authUrl}signin`, loginRequest).pipe(
      tap((response) => {
        const userId = response.id;
        const email = response.email;
        const userrole = response.roles.map((item) => item?.name);
        console.log('User ID:', userId);
        console.log('User Role:', JSON.stringify(userrole));
        console.log('Full Response:', JSON.stringify(response));
        localStorage.setItem('userId', userId); // Store user ID in localStorage
        localStorage.setItem('email', email);
        //@ts-ignore
        localStorage.setItem('userrole', userrole); // Store roles as a string
      })
    );
  }

  getUserEmail(): string {
    const email = localStorage.getItem('email');
    return email ? email : 'Unknown';
  }

  getCurrentUser(): Observable<Users> {
    const userId = localStorage.getItem('userId');
    if (userId) {
      return of({ id: userId } as Users);
    } else {
      return of(null);
    }
  }

  forgetPWD(forgetRequest: ForgetRequest): Observable<ForgetRequest> {
    return this.http.post<ForgetRequest>(`${this.authUrl}forgotPassword`, forgetRequest);
  }
  getLockedUsers(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}locked-users`, this.httpOptions);
  }

  resetPWD(forgetRequest: ForgetRequest, token: string): Observable<string> {
    return this.http.put(`${this.authUrl}resetPassword?token=${token}`, forgetRequest, { responseType: 'text' });
  }

  getAllUsers(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}users`, this.httpOptions);
  }
  getUser(id: String): Observable<Users> {
    return this.http.get<Users>(`${this.authUrl}findById/${id}`, this.httpOptions);
  }

  getUserByEmail(email: String): Observable<Users> {
    return this.http.get<Users>(`${this.authUrl}findByEmail/${email}`, this.httpOptions);
  }

  updateUser(user: Users): Observable<Users> {
    return this.http.put<Users>(`${this.authUrl}updateUser`, user, this.httpOptions);
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(`${this.authUrl}deleteUser/${id}`, this.httpOptions);
  }
  blockUser(userId: string, status: boolean) {
    return this.http.put<Boolean>(`${this.authUrl}blockUser/${userId}/${status}`, {}, this.httpOptions);
  }
  unarchiveUser(userId: string): Observable<any> {
    return this.http.put(`${this.authUrl}users/${userId}/unarchive`, {});
  }
  archiveUser(userId: string): Observable<any> {
    return this.http.put(`${this.authUrl}users/${userId}/archive`, {}, { responseType: 'text' });
  }
  getUsersAdmins(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}findUsersAdmins`, this.httpOptions);
  }
  getArchivedUsers(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}archived-users`, this.httpOptions);
  }

  disableUser(userId: string, status: boolean) {
    return this.http.put<Boolean>(`${this.authUrl}disableUser/${userId}/${status}`, {}, this.httpOptions);
  }
  getUsersByMatriculate(matriculate: string): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}findUsersByMatriculate/${matriculate}`, this.httpOptions);
  }

  checkPassword(encodedPassword: string, password: string): Observable<any> {
    return this.http.get<any>(
      `${this.authUrl}checkPassword?encPassword=${encodedPassword}&password=${password}`,
      this.httpOptions);
  }

  sendmail(email: string) {
    return this.http.post(`${this.authUrl}updateEmail/${email}`, {}, { responseType: 'text'});
  }

  validateToken(token: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.authUrl}validateToken/${token}`, this.httpOptions);
  }

  /*
    findAllBYScreening(): Observable<Auth[]> {
      return this.http.get<Auth[]>(`${this.authUrl}application/screening-applications`);
    }


    validate(application:Auth, id : string): Observable<Auth> {
      const path = `${this.authUrl}application/validate/`+id ;
      //@ts-ignore
      return this.http.put<Application>(path ,application , this.httpOptions);
    }

    reject(application:Auth, id : string): Observable<Auth> {
      const path = `${this.authUrl}application/reject/`+id ;
      //@ts-ignore
      return this.http.put<Application>(path ,application , this.httpOptions);
    }
  */


  findAll(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}application/list-applications`);
  }

  findAllBYScreening(): Observable<Auth[]> {
    return this.http.get<Auth[]>(`${this.authUrl}application/screening-applications`);
  }

  getApplication(id: string): Observable<Auth> {
    return this.http.get<Auth>(`${this.authUrl}application/app/${id}`, this.httpOptions);
  }

  screen(application: Auth, id: string): Observable<Auth> {
    const path = `${this.authUrl}application/screen/` + id;
    // @ts-ignore
    return this.httpclient.put<Application>(path, application, this.httpOptions);
  }

  validate(application: Auth, id: string): Observable<Auth> {
    const path = `${this.authUrl}application/validate/` + id;
    // @ts-ignore
    return this.httpclient.put<Application>(path, application, this.httpOptions);
  }

  reject(application: Auth, id: string): Observable<Auth> {
    const path = `${this.authUrl}application/reject/` + id;
    // @ts-ignore
    return this.httpclient.put<Application>(path, application, this.httpOptions);
  }

  verifyCode(codeVerification: string): Observable<any> {
    return this.http.post(environment.auth + 'verifyCode', { codeVerification }, this.httpOptions);
  }
  regenerateVerificationCode(token: string): Observable<any> {
    const params = new HttpParams().set('token', token);
    return this.http.post('https://gateway.manajero.com/api/auth/regenerateCode', null, { params , responseType: 'text' });
  }

  sendSMStwilio(phoneNumber: string): Observable<any> {
    const body = { phoneNumber };
    return this.http.post('https://gateway.manajero.com/api/auth/router/sendOTP', body);
  }

  regenerateCodeWithSMS(token: string): Observable<any> {
    const params = new HttpParams().set('token', token);
    return this.http.post('https://gateway.manajero.com/api/auth/regenerateCode/sms', null, { params, responseType: 'text' });
  }
  getApiStatusByName(apiName: string): Observable<ExternApi> {
    return this.http.get<ExternApi>(`${this.apiUrl}/findByName/${apiName}`);
  }
}